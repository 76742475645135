<template>
  <vx-card  style="padding-left: 10px">

    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createTutorHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Add Tutor</vs-button>
      </div>
    </div>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      :sst="true"
      :total="totalDocs"
      :data="users"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="dataTableParams.limit"
      search
      :noDataText="noDataText"
    >
      <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between" >
        <div style='float: left'>
          <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit" class="mr-6">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in limitOptions" :clearable="false"/>
          </vs-select>
        </div>
      </div>

      <template slot="thead">
            <vs-th sort-key="fullName">Name</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="tutoringLocation">Tutoring Location</vs-th>
            <vs-th sort-key="tutorRate">Rate</vs-th>
            <vs-th sort-key="averageRating">Average Rating</vs-th>
            <vs-th sort-key="profileCreated">Profile Completed</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <vs-th sort-key="isWWCCVerified">WWCC Verified</vs-th>
            <vs-th sort-key="createdAt">Created At</vs-th>
            <vs-th sort-key="action">Action</vs-th>
      </template>

      <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].fullName">
                    {{ data[indextr].fullName}}
                </vs-td>
                <vs-td :data="data[indextr].email">
                    {{ data[indextr].email}}
                </vs-td>
                <vs-td :data="data[indextr].tutoringLocation">
                    {{ data[indextr].tutoringLocation || 'N/A'}}
                </vs-td>
                <vs-td :data="data[indextr].tutorRate">
                    ${{ data[indextr].tutorRate || 0}}
                </vs-td>
                <vs-td :data="data[indextr].averageRating">
                    {{data[indextr].averageRating > 0 ? data[indextr].averageRating.toFixed(2) : 0}}<span v-if="data[indextr].averageRating > 0" style="font-size:0.7em;">({{data[indextr].ratingsCount}})</span>
                </vs-td>
                <vs-td :data="data[indextr].profileCreated">
                    <vs-icon v-if="data[indextr].profileCreated" icon="check_circle" size="small" color="#0b55bd"></vs-icon>
                    <vs-icon v-else icon="cancel" size="small" color="#bd0b0b"></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].status">
                       <vs-chip v-if="data[indextr].status === 'Enabled'" color="success">Active</vs-chip>
                       <vs-chip v-else color="danger">Disabled</vs-chip>
                </vs-td>
              <vs-td :data="data[indextr].status">
                <vs-chip v-if="data[indextr].isWWCCVerified === true" color="success">Yes</vs-chip>
                <vs-chip v-else color="danger">No</vs-chip>
              </vs-td>
              <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter('DD/MM/YYYY') }}</vs-td>
              <vs-td :data="data[indextr]._id">
                  <p>
                    <vx-tooltip text="View Details">
                      <vs-icon class="mr-2" icon-pack="feather" icon="icon-eye" size="small" @click="viewDetailHandler(data[indextr]._id)"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Edit Details">
                      <vs-icon class="mr-2" icon-pack="feather" icon="icon-edit" size="small" @click="editDetailHandler(data[indextr]._id)"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Disable User" v-if="data[indextr].status ==='Enabled'">
                      <vs-icon class="mr-2" icon-pack="feather" icon="icon-slash" size="small" @click="openDeleteConfirm(data[indextr]._id)"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Delete User">
                      <vs-icon class="mr-2" icon-pack="feather" icon="icon-trash" size="small" @click="openUserDeleteConfirm(data[indextr]._id)"></vs-icon>
                    </vx-tooltip>
                  </p>
                  </vs-td>
            </vs-tr>
        </template>

    </vs-table>
    <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </div>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions } from 'vuex';
import moment from "moment";

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      noDataText:'No tutors available..',
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        page: 1,
        limit: 25
      },
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };
  },
  methods: {
    ...mapActions('tutor', ['fetchTutors','updateTutorById','deleteUserById']),
    moment(date){
      return moment(date)
    },
    getTutorsList() {
      let self = this;
      this.fetchTutors(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
      })

    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTutorsList();
    },
     handleChangePage(page) {
      this.dataTableParams.page = page;
       this.getTutorsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.currentPage = 1;
      this.$refs.table.currentx = 1;
      this.getTutorsList();
    },
    createTutorHandler(){
      this.$router.push({ name: 'AddTutor'})
    },
    viewDetailHandler(id) {
      this.$router.push({ name: 'TutorDetails', params: { tutorId: id } })
    },
    editDetailHandler(id) {
      this.$router.push({ name: 'EditTutorDetails', params: { tutorId: id } })
    },
    openDeleteConfirm(tutorId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Disable`,
        text: `Are you sure you want to disable this user?`,
        accept: this.deleteDetailHandler,
        acceptText: "Yes",
        parameters: tutorId
      })
    },
    openUserDeleteConfirm(tutorId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `This will permanently  delete user from the system. All user profile information, all lesson booking, request  booking, report will be removed. Are you sure you want to delete this user?`,
        accept: this.userDeleteHandler,
        acceptText: "Yes",
        parameters: tutorId
      })
    },
    userDeleteHandler(tutorId){
      this.deleteUserById({tutorId}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: 'User deleted successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
        this.getTutorsList();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    deleteDetailHandler(tutorId) {
      let tutorData = {status : 'Disabled'}
      this.updateTutorById({tutorId, tutorData}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: 'User disabled successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
        this.getTutorsList();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  watch: {
  	'dataTableParams.page': function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.dataTableParams.page = newVal;
        this.getTutorsList();
      }
    },
    'dataTableParams.limit': function(newlimit, oldLimit) {
      if(newlimit !== oldLimit){
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getTutorsList()
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getTutorsList();
  }
};
</script>
<style scoped>
.actionButtons{
  display: inline-block;
}
.actionButtons .actionButton{
  float: left;
}
</style>
